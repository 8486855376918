import Image from 'next/image';

const ManageAtEase = () => {
  return (
    <section className="mat-ease">
      <div className="mat-ease--container">
        <div className="mat-ease--header">
          <div className="mat-ease--header-img">
            <Image
              src="/images/illustrations/manage_at_ease.webp"
              alt="Collect all of you want"
              width={400}
              height={475}
              layout="responsive"
            />
          </div>
        </div>

        <div className="mat-ease--content-main">
          <div className="mat-ease--content">
            <div className="mat-ease--content-decoration">
              <div className="mat-ease--content-src">
                <Image
                  src="/images/icons/dot.png"
                  alt="dot"
                  width={90}
                  height={90}
                  layout="fixed"
                />
              </div>
              <h2 className="mat-ease--content-title">Manage at Ease</h2>
            </div>
            <div className="mat-ease--content-info">
              <p className="mat-ease--content-desc">
                No more physical cards that occupy your wallet or download different apps for your{' '}
                membership
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageAtEase;
